
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthService } from "src/app/common/providers/auth.service";
// import { MailService } from "src/app/mail/shared/services/mail-service";
// import { CommonUtils } from "src/app/common/utils/common-util";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: "./logout-confirmation.html",
  styleUrls: ["./logout-confirmation.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class LogoutConfirmationComponent implements OnInit, OnDestroy {
  hasPendingMessages = false;
  logoImage: SafeResourceUrl;
  currentTheme: string = "default";

  constructor(
              private auth: AuthService,
              private translate: TranslateService,
              private domSanitizer: DomSanitizer,
              private dialog: MatDialogRef<LogoutConfirmationComponent>
              ) {


    this.logoImage = this.domSanitizer.bypassSecurityTrustResourceUrl(this.getFullUrl("/assets/images/logout-moblie.png"));
    const pendingMessages = localStorage.getItem("pendingMessages");
    if (pendingMessages) {
      const messages = JSON.parse(pendingMessages);
      if (messages.length > 0) {
        this.hasPendingMessages = true;
      }
    }

  }

  getBaseUrl(app?: string) {
    const baseUrl = window.location.href;

    if (environment.isCordova) {
      const split = baseUrl.startsWith("https://") ? "/www" : "/" + window.location.href.split("app://localhost/")[1].split("/")[0];
      return baseUrl.startsWith("https://") ? "" : window.location.href.split(split)[0].replace(/index.html/gi, "");
    } else if (environment.isElectron) {
      if (app && app === "contacts") {
        return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/contacts")[0];
      } else if (app && app === "calendar") {
        return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/calendar")[0];
      } else if (app && app === "briefcase") {
        return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/briefcase")[0];
      } else if (app && app === "preferences") {
        return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/preferences")[0];
      } else {
        return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/app.asar")[0] + "/app.asar";
      }
    } else {
      return "";
    }
  }

  getFullUrl(url: string, app?: string) {
    return this.getBaseUrl(app) + url;
  }


  ngOnInit() {

  }

  ngOnDestroy() {
    if (document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "none";
    }
  }

  logout() {
    this.dialog.close(true);
  }

  cancel(): void {
    this.dialog.close();
  }

}
