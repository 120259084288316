<vnc-dialog
  [classes]="['logout-from-mail-app']"
  classes="fixed-size with-border with-border-radius"
  [bodyTemplate]="bodyTemplate"
  [hideHeader]="true"
  [footerTemplate]="footerTemplate"
>
</vnc-dialog>

<ng-template #bodyTemplate>
  <div class="img-div">
    <img [src]="logoImage" />
  </div>
  <div class="logout-div">
    {{ 'LOGOUT_MSG_MAIL' | translate }}
  </div>
</ng-template>
<ng-template #footerTemplate>
  <div class="footer-buttons" [ngClass]="{ 'dfb-theme' : currentTheme == 'dfb'}">
    <vnc-link-button [size]="'s'" [isPending]="false" label="{{ 'CANCEL' | translate }}" (onClick)="cancel()"></vnc-link-button>
    <vnc-primary-button [size]="'xs'" (onClick)="logout()" label="{{ 'LOGOUT' | translate }}">
    </vnc-primary-button>
  </div>
</ng-template>